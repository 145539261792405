$color-strauss-red: #e30613;
$color-strauss-light-red: #ff0818;
$color-strauss-black: #000000;
$color-strauss-dark-grey: #999999;
$color-strauss-mid-grey: #d3d4d4;
$color-strauss-light-grey: #f0f1f1;
$color-strauss-editor-background: #f4f4f4;
$color-strauss-white: #ffffff;
$color-strauss-green: #96cc9c;
$color-strauss-blue: #0088ff;

$const-strauss-sketch-width: 24;
$const-strauss-sketch-height: 21.5;

$const-strauss-sketch-element-width: 24;
$const-strauss-sketch-element-height: 21;

@font-face {
    font-family: 'Augusta Regular';
    src: url(../fonts/Augusta/WOFF/Augusta-Regular.woff);
}

@font-face {
    font-family: 'Augusta Semilight';
    src: url(../fonts/Augusta/WOFF/Augusta-SemiLight.woff);
}

@font-face {
    font-family: 'Roboto Regular';
    src: url(../fonts/Roboto/Roboto-Regular.ttf);
}

@font-face {
    font-family: 'Roboto Medium';
    src: url(../fonts/Roboto/Roboto-Medium.ttf);
}

@font-face {
    font-family: 'Roboto Bold';
    src: url(../fonts/Roboto/Roboto-Bold.ttf);
}

h1 {
    color: $color-strauss-red;
    font-family: 'Augusta Regular';
    text-transform: uppercase;
}

h2 {
    color: $color-strauss-black;
    font-family: 'Augusta Semilight';
    text-transform: uppercase;
}

a  {
    color: $color-strauss-black;
}

html {
    font-family: 'Roboto Regular', sans-serif;
    
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.button {
    height: 48px;
    padding: 0 30px;
    font-size: 14px;
    font-family: 'Roboto Medium';
    border-radius: 50px;
    cursor: pointer;
    user-select: none;
    display: inline-block;
    vertical-align: middle;
    line-height: 48px;
    border: none;
    text-decoration: none;
}

.buttonSmall {
    height: 30px;
    line-height: 30px;
    font-size: 12px;
    padding: 0 20px;
}

.buttonBig {
    height: 58px;
    font-size: 14px;
    line-height: 58px;
    padding: 0 35px;
}

.buttonBigMultiline {
    height: 58px;
    font-size: 12px;
    line-height: 17px;
    padding: 12px 35px;
}

.buttonPrimary {
    @extend .button;
    background-color: $color-strauss-red;
    color: $color-strauss-white;
}
.buttonPrimary:hover {
    background-color: $color-strauss-light-red;
    color: $color-strauss-white;
}
.buttonPrimary:disabled {
    background-color: $color-strauss-white;
    color: $color-strauss-mid-grey;
    border: 1px solid $color-strauss-mid-grey;
}

.buttonSecondary {
    @extend .button;
    background-color: $color-strauss-light-grey;
    color: $color-strauss-black;
}
.buttonSecondary:hover {
    background-color: $color-strauss-mid-grey;
    color: $color-strauss-black;
}
.buttonSecondary:disabled {
    background-color: $color-strauss-white;
    color: $color-strauss-mid-grey;
    border: 1px solid $color-strauss-mid-grey;
}

.buttonSub {
    @extend .button;
    background-color: $color-strauss-white;
    color: $color-strauss-black;
    border: 1px solid $color-strauss-mid-grey;
}
.buttonSub:hover {
    background-color: $color-strauss-white;
    color: $color-strauss-black;
    border: 1px solid $color-strauss-black;
}
.buttonSub:disabled {
    background-color: $color-strauss-white;
    color: $color-strauss-mid-grey;
    border: 1px solid $color-strauss-mid-grey;
}

.buttonActive {
    background-color: $color-strauss-black !important;
    color: $color-strauss-white !important;
}

.buttonCircle {
    padding: 0;
    width: 46px;
    height: 46px;
}


input[type="text"] {
    width: 100%;
    border: none;
    border-bottom: 1px solid $color-strauss-dark-grey;
    font-family: 'Roboto Medium';
    font-style: italic;
    font-size: 18px;
    padding-bottom: 10px;
}


.pocketInfo {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 40px;
    flex-shrink: 0;

    .pocketInfoIcon {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid $color-strauss-black;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        svg {
            width: 60%;
            height: 60%;
        }
    }

    .pocketInfoText {
        vertical-align: middle;
        font-size: 13px;
        margin: 0 0 0 10px;
        font-family: 'Roboto Medium';
    }
    .pocketInfoTextBold {
        vertical-align: middle;
        font-size: 13px;
        margin: 0 0 0 10px;
        font-family: 'Roboto Medium';
        font-weight: bold;
    }
}

.pocketError {
    color: $color-strauss-red;
}

.pocketInfoRed {
    color: $color-strauss-red;

    .pocketInfoIcon {
        svg {
            fill: $color-strauss-red;
        }
        border-color: $color-strauss-red;
    }
}

.pocketFooterTotalContainer {
    flex-grow: 1;
        
    .pocketFooterTotalVatForm {
        margin-bottom: 20px;
        height: 24px;
        display: flex;
        flex-direction: revert;
        align-items: center;
    }

    .pocketFooterTotalVatLabel {
        font-family: 'Roboto Regular';
        font-size: 12px;
    }

    .pocketFooterTotalVatSwitch {
        margin: 0 10px;

        input {
            height: 24px;
            width: 48px;
            margin-top: 0;
            background-color: $color-strauss-light-grey;    
            border-color: $color-strauss-light-grey;     
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Cfilter id='shadow'%3E%3CfeDropShadow dx='0.1' dy='0.1' stdDeviation='0.4' flood-color='black' flood-opacity='0.4'/%3E%3C/filter%3E%3Cg filter='url(%23shadow)'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E");            
            cursor: pointer;
        }
        
        input:checked {
            background-color: $color-strauss-red;      
            border-color: $color-strauss-red;                
        }
    }

    .pocketFooterTotalText {
        margin: 0;
        font-size: 14px;
        font-family: 'Roboto Medium';
        font-weight: bold;
        text-transform: uppercase;
    }

    .pocketFooterTotalPrice {
        margin: 0;
        font-size: 39px;
        font-weight: bold;
        font-family: 'Roboto Medium';
    }
}

*:focus {
    outline: none;
}

.mobileOnly {
    display: none !important;
}

@media ( max-width: 820px ) { 
    .buttonCircle {
        padding: 0;
        width: 34px;
        height: 34px;
    }

    .mobileOnly {
        display: initial !important;
    }
    
    .desktopOnly {
        display: none !important;
    }

    .pocketFooterTotalContainer {
        p {
            text-align: center;
        }
    }
}