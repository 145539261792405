@import './theme.module.scss';

.canvas {
    background-color: transparent;
    padding: 0;
}

.attachContainer {
    background-color: rgba(0, 0, 0, 0.8);
    color:  $color-strauss-white;
    text-transform: uppercase;
    font-family: 'Roboto Medium';
    font-size: 22px;
    border-radius: 35px;
    padding: 11px;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;

    .attachHeader {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    span {
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        margin: 0 20px;
    }

    .attachArrow {
        background-color: $color-strauss-white;
        height: 48px;
        width: 48px;
        border-radius: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            height: 15px;
            width: 15px;
        }
    }

    .pocketList {
        width: 100%;

        p {
            margin: 0;
            margin-top: 10px;
            text-align: center;
            font-size: 16px;
            padding: 10px 0;
        }
    
        .attachEmpty {
            font-style: italic;
        }
    }
}

.visSwitcherContainer {
    position: absolute;
    left: 40px;
    bottom: 40px;
    width: 200px;
    height: 200px;
    background-image: url('../../public/images/vis_shorts.png');
    background-size: contain;
    background-repeat: no-repeat;

    &.isVest {
        background-image: url('../../public/images/vis_vest.png');
    }

    .buttonSwitch {
        background-color: $color-strauss-white;
        position: absolute;
        top: 30px;
        right: 0;
        width: 70px;
        height: 70px;
        padding: 0;

        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

        }
        &:hover {
            background-color: $color-strauss-mid-grey;
        }
    }
}


@media ( max-height: 800px ) { 

    .visSwitcherContainer {
        position: absolute;
        left: 40px;
        bottom: 40px;
        width: 100px;
        height: 100px;
        background-image: url('../../public/images/vis_shorts.png');
        background-size: contain;
        background-repeat: no-repeat;

            
        .buttonSwitch {
            top: 0;
            right: 0;
            width: 50px;
            height: 50px;
        }
    }
}

@media ( max-width: 991px ) { 
    .attachContainer {
            font-size: 14px;

            .attachArrow {
                height: 34px !important;
                width: 34px !important;
                border-radius: 17px !important;
            }
            span {
                height: 34px;
                line-height: 34px;
                vertical-align: middle;
                margin: 0 20px;
            }

            
        .pocketList {
            width: 100%;

            p {
                font-size: 14px;
            }
        }
    }

    .visSwitcherContainer {
        position: absolute;
        left: calc(50% - 100px);
        bottom: 100px;
        width: 200px;
        height: 200px;
        background-image: url('../../public/images/vis_shorts.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

        
    .visSwitcherContainer {
        right: 10px;
        top: 30px;
        left: initial;
        bottom: initial;
        width: 70px;
        height: 70px;

        .buttonSwitch {
            background-color: $color-strauss-white;
            position: absolute;
            top: 30px;
            right: 40px;
            width: 40px;
            height: 40px;

            svg {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

            }
            &:hover {
                background-color: $color-strauss-mid-grey;
            }
        }
    }
}
