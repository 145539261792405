@import './theme.module.scss';

.container {
    padding: 25px 40px 0px 40px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
}
.content {
    background: transparent linear-gradient(180deg, rgba(235,235,235,1) 0%, rgba(245,245,245,1) 67%, rgba(239,239,239,1) 67.5%, rgba(235,235,235,1) 100%);
    position: relative;
    flex: 1;
}

.header {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.language {
    display: flex;
    justify-content: center;
}

.buttonCircle {
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        height: 40%;
    }
}
.buttonInfo {
    margin-left: 70px;
}

.editButton.buttonActive {
    svg {
        fill: $color-strauss-white !important;
    }
}

.pocketContainer {
    margin: 40px 40px 40px 0;
    padding: 40px;
    position: absolute;
    right: 0;
    background-color: $color-strauss-white;
    box-shadow: 0px 2px 4px #0000000D;
    border-radius: 5px;
    height: calc(100% - 80px);
    width: 50vw;
    max-width: 500px;
    overflow-y: overlay;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.pocketContainer::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}


.pocketTitle {
    font-family: 'Roboto Medium';
    font-size: 12px;
    text-transform: uppercase;
}

.pocketNameContainer {
    position: relative;

    input[type="text"] {
        width: calc(100% - 50px);
        margin-bottom: 16px;
    }
}

.pocketName {
    font-family: 'Augusta Regular';
    font-size: 26px;
}

.pocketNameEdit {
    height: 39px;
    width: 39px;
    padding: 0;
    line-height: 39px;
    position: absolute;
    top: 0;
    right: 0;
    
    svg {
        height: 30%;
    }
}

.pocketScrollContainer {
    overflow-y: overlay;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    flex-grow: 1;
    margin-bottom: 20px;
    min-height: 300px;
}
.pocketScrollContainer::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}


.pocket {
    background-color: $color-strauss-light-grey;
    border: 1px solid #F0F1F1;
    border-radius: 5px;
    margin-bottom: 12px;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    img {
        height: 30px;
        pointer-events: none;
    }

    p {
        font-size: 12px;
        margin: 0;
    }

    .moduleTextContainer {
        padding: 0 10px;
        flex-grow: 1;
        pointer-events: none;
    }

    .moduleTitle {
        font-weight: bold;
    }
        
    .moduleRemoveButton {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        svg: {
            width: 100%;
            height: 100%;
        }
        
    }
}

.pocketFooter {
    display: flex;
    align-items: center;
    gap: 20px;
}

.pocketFooterTotalContainer {
    flex-grow: 1;
}

.pocketFooterTotalText {
    margin: 0;
    font-size: 10px !important;
}

.pocketFooterTotalPrice {
    margin: 0;
    font-size: 22px !important;
    font-weight: bold;
}

.pocketActive {
    background-color: $color-strauss-black;
    color: $color-strauss-white;
}

.pocketButton {
    border-radius: 5px;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    svg {
        height: 50%;
        margin-right: 10px;
    }
}

.modulesInfo {
    font-size: 12px;
    margin-top: 48px;
}

.moduleAddButton {
    border-radius: 5px;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 16px;

    svg {
        height: 60%;
        margin-right: 20px;
    }
}

.moduleContainer {
    margin: 40px 40px 40px 0;
    position: absolute;
    right: calc(min(50vw, 500px) + 30px);
    background-color: $color-strauss-white;
    box-shadow: 0px 2px 4px #0000000D;
    border-radius: 5px;
    height: calc(100% - 80px);
    width: 50vw;
    max-width: 500px;
    z-index: 1;
}

.moduleContainerScroll {
    margin: 0;
    padding: 40px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    overflow-y: auto;
    overflow-y: overlay;
}

.slideIn {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 49%, #FFFFFFCC 68%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% - 20px);
    height: 40px;
    z-index: 2;
}
.slideOut {
    background: transparent linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 49%, #FFFFFFCC 68%, #FFFFFF00 150%) 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 5px 5px;
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(100% - 20px);
    height: 40px;
    z-index: 2;
}

.categoryHeader {
    color: $color-strauss-black;
    font-size: 12px;
    margin-bottom: 0;
    position: relative;
}
.categorySubHeader {
    font-size: 10px;
}

.categoryInfoIcon {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 2px solid $color-strauss-black;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    svg {
        width: 60%;
        height: 60%;
    }
    position: absolute;
    right:0;
    top: 0;
    cursor: pointer;
}

.categoryContainer {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(3, 1fr);
}

.categoryModuleContainer {
    background-color: $color-strauss-light-grey;
    border-radius: 10px;
    min-height: 220px;
    text-align: center;
    position: relative;

    img {
        margin-top: 30px;
        margin-bottom: 20px;
    }
        
    .categoryModuleName {
        font-weight: bold;
        font-size: 14px;
        margin-bottom: 7px;
    }
    .categoryModulePrice {
        font-size: 14px;
    }
    .categoryModuleAddHover {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
        transition: opacity 0.5s;
        background-color: rgba($color: $color-strauss-red, $alpha: 0.7);
        border-radius: 10px;

        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 50%;
        }
    }

    .categoryModuleDisabled {
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.9;
        background-color: rgba($color: $color-strauss-light-grey, $alpha: 0.7);
        border-radius: 10px;
    }
}

.categoryModuleContainer:hover {
    .categoryModuleAddHover {
        opacity: 1.0;
    }
}


.categoryContainer + .categoryHeader {
    margin-top:  40px;
}

.categoryHeader + .categoryContainer {
    margin-top:  16px;
}

.editorModuleButtons {
    position: absolute;
    width: 60px;
    height: 400px;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    pointer-events: none;
}

.editorModuleButtonsLeft {

}


.editButton {
    position: relative;
    width: 60px;
    height: 60px;
    border-radius: 5px;
    background-color: $color-strauss-white;
    font-size: 10px;
    margin-bottom: 10px;
    box-shadow: 0px 2px 4px #0000000D;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    line-height: inherit;
    pointer-events: initial;

    svg {
        width: 17px;
        height: 17px;
        margin-bottom: 5px;
    }
    span {
        margin-top: 3px;
        text-transform: uppercase;
    }
        
    .editorModuleButtonInfoText {
        display: none;
        background-color: rgba($color: $color-strauss-white, $alpha: 0.7);
        position: absolute;
        right: 100%;
        height: 100%;
        top: 0;

        font-family: 'Roboto Bold';
        font-size: 16px;

        justify-content: center;
        align-items: center;
        padding: 10px;
        margin: 0;
        white-space: nowrap;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }
}

.editButton.buttonActiveContent {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    .editorModuleButtonInfoText {
        display: flex;
        color: $color-strauss-black;
    }
}

.editorModuleButtonsRight {
    .editButton.buttonActiveContent {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;

        .editorModuleButtonInfoText {
            left: 100%;
            right: initial;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
}

.previewButton {
    position: absolute;
    right: calc(70px + min(50vw, 500px));
    bottom: 124px;
}

.printButton {
    position: absolute;
    right: calc(70px + min(50vw, 500px));
    bottom: 40px;
}

.overviewButton {
    display: none;
}

.editorCenter {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: calc(100% - (40px + min(50vw, 500px)));
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}
.editorOuterContainerContainer {
    position: relative;
    width: calc(min(80%, 80vh));
    padding-bottom: calc(min(80%, 80vh) * #{$const-strauss-sketch-height} / #{$const-strauss-sketch-width});
}
.editorOuterContainer {
    transform: scale(1);
    width: 100%;
    left: 0;
    height: 100%;
    top: 0;
    position: absolute;
    pointer-events: initial;
}
.editorContainer {
    background-color: $color-strauss-dark-grey;
    position: absolute;
    width: 100%;
    height: 100%;
}

.allowedRegion {
    position: absolute;
    background-color: $color-strauss-green;
}

.editorBorderBackground {
    position: absolute;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background-color: $color-strauss-white;
    overflow: hidden;
}
.editorBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;

    background-image: url('../icons/grid.svg');
    background-size: calc(100% / #{$const-strauss-sketch-width});
    background-position: bottom left;
    overflow: hidden;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.editorModule {
    position: absolute;
    background-size: 100% 100%;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        pointer-events: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
}

.editorPocketDirection-right {
    .editorModule {
        img {
            transform: scaleX(-1);
        }
    }
}

.editorModuleMove {
    cursor: grabbing;
}

.editorModuleActive::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    content: ' ';
    border: 5px solid $color-strauss-blue;
}

.editorModuleNotAllowed::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    content: ' ';
    border: 5px solid $color-strauss-red;
}

.editorWidgetScale {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 55%;
    border: 5px solid $color-strauss-blue;
    background-color: $color-strauss-white;
    z-index: 1;
}

.editorWidgetScaleTopLeft {
    left: 0;
    top: 0;
    margin-left: -16px;
    margin-top: -16px;
}
.editorWidgetScaleTopRight {
    right: 0;
    top: 0;
    margin-right: -16px;
    margin-top: -16px;
}
.editorWidgetScaleBottomRight {
    right: 0;
    bottom: 0;
    margin-right: -16px;
    margin-bottom: -16px;
}
.editorWidgetScaleBottomLeft {
    left: 0;
    bottom: 0;
    margin-left: -16px;
    margin-bottom: -16px;
}
.editorWidgetScaleTop {
    left: 50%;
    top: 0;
    margin-left: -16px;
    margin-top: -16px;
}
.editorWidgetScaleLeft {
    left: 0;
    top: 50%;
    margin-left: -16px;
    margin-top: -16px;
}
.editorWidgetScaleRight {
    right: 0;
    top: 50%;
    margin-right: -16px;
    margin-top: -16px;
}
.editorWidgetVolumeRight {
    right: -5px;
    top: 50%;
    margin-right: -16px;
    margin-top: -16px;
}

.editorWidgetIcon {
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: $color-strauss-blue;

    svg {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 30px;
        height: 30px;

        fill: $color-strauss-white !important;
    }
    pointer-events: none;
}

.editorWidgetIconMove {
    top: 50%;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
}


.editorWidgetIconScale {
    top: 0;
    left: 0;
}

.editorWidgetIconHeight {
    top: 0;
    left: 50%;
    margin-left: -30px;
}
.editorWidgetIconExtend {
    top: 50%;
    left: 0;
    margin-top: -30px;
}

.editorVolumeBase {
    position: absolute;
    height: calc(100% + 10px);
    left: calc(9 * 100% / #{$const-strauss-sketch-width} );
    top: -5px;
    width: calc(100% / #{$const-strauss-sketch-width});
    border: 5px solid $color-strauss-mid-grey;
    background-color: $color-strauss-white;
}

.editorModuleSide {
    position: absolute;
    left: calc(9 * 100% / #{$const-strauss-sketch-width});
    background-size: 100% 100%;
    border: 5px solid $color-strauss-blue;
    box-sizing: border-box;

    img {
        width: 100%;
        height: 100%;
        pointer-events: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
}

.printViewBackground {
    background-color: rgba(#000000, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.printView {
    position: relative;
    background: $color-strauss-white;
    padding: 40px;
    border-radius: 10px;
}
.printViewHeader {
    font-size: 28px;
    color: $color-strauss-black;
    height: 48px;
    line-height: 48px;
    vertical-align: middle;
    padding-right: 70px;
}
.printViewCloseButton {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 48px;
    height: 48px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        height: 40%;
    }
}

.printViewFooter {
    display: flex;
    align-items: flex-end;

    .printViewFooterRight {
        margin-left: 30px;
        max-width: 30vw;
    }

    h2 {
        font-size: 16px;
    }
    p {
        font-size: 16px;
        margin: 0;
    }
}

.printViewPageContainer {
    position: relative;
    border: 1px solid $color-strauss-dark-grey;
    height: 50vh;
    width: 35.353535vh;
    margin-top: 55px;
    margin-bottom: 55px;
    margin-left: auto;
    margin-right: auto;
}

.printViewModuleContainer {
    transform: rotate(90deg);
    position: relative;
    margin-top: 4vh;
    margin-right: calc(2.525252vh * 2);
    height: 35.353535vh;
}
.printPageHeader {
    color: $color-strauss-black;
    font-size: 1.4vh;
    margin-left: 1.5vh;
    margin-top: 2.5vh;
    margin-bottom: 0.2vh;
}
.printPageCode {
    margin-left: 1.5vh;
    font-size: 1vh;
    font-family: 'Roboto Regular';
}
.editorContainerPrint {
    background-color: $color-strauss-black;
}
.editorOuterContainerPrint {
    width: calc(35.353535vh);
    padding-bottom: calc(35.353535vh * #{$const-strauss-sketch-height} / #{$const-strauss-sketch-width});
}
.editorBackgroundPrint {
    background-image: none;
    background-color: $color-strauss-white;
}
.editorBorderBackgroundPrint {
    left: 3px;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
}
.editorPrintCutLine {
    position: absolute;
    top: 5vw;
    width: 90%;
    left: 5%;
    border-bottom: 2px dashed #f0f1f1;
}

@page {
    size: A3;
    margin: 0;
}

@media print {
    html, body {
        width: 297mm;
        height: 420mm;
    }

    .printPageHeader {
        font-size: 2.8vh;
        margin-left: 3vh;
        margin-top: 5vh;
        margin-bottom: 0.4vh;
    }
    .printPageCode {
        margin-left: 3vh;
        font-size: 2vh;
    }
    .editorPrintCutLine {
        top: 70mm;
    }
}

.printViewPrintButton {
    
}

.previewContainer {
    background: transparent linear-gradient(180deg, rgba(235,235,235,1) 0%, rgba(245,245,245,1) 67%, rgba(239,239,239,1) 67.5%, rgba(235,235,235,1) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}


.rulerHorizontalCenter {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: calc(100% - (40px + min(50vw, 500px)));
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.rulerHorizontalCenterContainer {
    position: relative;
    width: calc(min(80%, 80vh));
    height: 100%;
}

.rulerHorizontal {
    width: 100%;
    height: 15px;
    background-image: url('../icons/ruler_horizontal.svg');
    background-size: calc(100% / #{$const-strauss-sketch-width} * 5) 100%;
    background-position: top left;
    position: absolute;
    margin: 0;
    top: 10px;
}

.rulerVerticalCenter {
    position: absolute;
    left: -25px;
    top: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    pointer-events: none;
}

.rulerVerticalCenterContainer {
    position: relative;
    padding-bottom: calc(min(80%, 80vh) * #{$const-strauss-sketch-height} / #{$const-strauss-sketch-width});
    width: calc(min(80%, 80vh));
    left: 0;
}

.rulerVertical {
    height: 100%;
    width: 15px;
    background-image: url('../icons/ruler_vertical.svg');
    background-size: 100% calc(100% / #{$const-strauss-sketch-height} * 5);
    background-position: bottom left;
    position: absolute;
    margin: 0;
    left: 0;
}

.rulerNumbersVertical {
    height: 100%;
    position: absolute;
    bottom: 0;
    margin: 0;
    left: 0;
    width: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 0.5vmax;
    text-align: right;
    color: #999999;
    padding-bottom: 5px;
}

.rulerNumbersHorizontal {
    width: 104.1%;
    position: absolute;
    top: 7px;
    margin: 0;
    left: -4.7%;
    height: 15px;
    display: flex;
    flex-direction: row;
    font-size: 0.5vmax;
    text-align: right;
    color: #999999;
    padding-left: 0px;

    span {
        width: calc(100% / (#{$const-strauss-sketch-width} + 1));
    }
}

.linkfooter {
    justify-content: flex-end !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

@media ( max-width: 991px ) { 
    .container {
        padding: 0;
    }

    .header {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        padding: 20px 20px 0 20px;
    }
    
    .language {
        display: none;
    }

    .buttonCircle {
        margin-left: 10px;
        height: 28px;
        width: 28px;
    }

    .pocketContainer {
        display: none;
        max-width: initial;
    }

    .pocketContainerActive {
        display: initial;
        position: absolute;
        left: 0;
        bottom: 50px;
        margin: 0;
        padding: 30px;
        height: calc(70vh - 50px);
        width: 100%;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        z-index: 1;
    }

    .pocketTitle {
        display: none;
    }

    .pocketNameContainer {
        input[type="text"] {
            width: calc(100% - 60px);
            margin-bottom: 16px;
            font-size: 18px;
        }
    }
    .pocketName {
        font-family: 'Augusta Regular';
        font-size: 18px;
        margin-bottom: 28px;
    }
    .modulesInfo {
        display: none;
    }

    .pocketScrollContainer {
        height: calc(70vh - 220px);
    }

    .pocketFooter {
        button {
            display: none;
        }
        display: flex;
        align-items: center;
        gap: 20px;
        position: absolute;
        width: calc(100% - 60px);
        left: 30px;
        bottom: 40px;
    }

    .pocketFooterTotalContainer {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .pocketFooterTotalText {
        font-family: 'Roboto Regular' !important;
        font-size: 16px !important;
    }

    .pocketFooterTotalPrice {
        font-size: 22px !important;
    }

    .pocketButtonsDesktop {
        display: none;
    }

    .editorCenter {
        width: 100%;
    }

    .rulerHorizontalCenterContainer {
        display: none;
    }

    .rulerVerticalCenterContainer {
        display: none;
    }

    .editorCenter {
        align-items: flex-start;
        padding-top: 40px;
    }

    .editorOuterContainerContainer {
        width: 90vw;
        padding-bottom: calc(90vw * 21.5 / 24);
    }

    .content {
        background-color: $color-strauss-light-grey;
        background-image: none;
    }

    .printButton {
        display: none;
    }

    .editButton {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        font-size: 8px;
        margin-bottom: 10px;
        padding: 0;
        svg {
            width: 17px;
            height: 17px;
            margin-bottom: 3px;
        }
        span {
            margin-top: 1px;
            text-transform: uppercase;
        }
    }
    
    .previewButton {
        position: absolute;
        right: 20px;
        bottom: 130px;
        margin-bottom: 0;
    }

    .pocketButton.mobileOnly {
        position: absolute;
        bottom: 130px;

        border: none;
        width: 60px;
        height: 50px;
        padding: 0;
        background: none;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .pocketButtonLeft {
        left: 20px;
    }

    .pocketButtonRight {
        left: 90px;
    }


    .pocketButtonLeft.buttonActive {
        background: none;
        background-color: transparent !important;
    }

    .pocketButtonRight.buttonActive {
        background: none;
        background-color: transparent !important;
    }

    .overviewButton {
        position: absolute;
        left: 20%;
        bottom: 17px;
        font-family: 'Roboto Medium';
        width: 50px;
        height: 50px;
        margin: 0;
        margin-left: -25px;
        background-color: transparent;
        border: none;
        font-size: 12px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: inherit;
        svg {
            width: 30px;
            height: 30px;
            fill: $color-strauss-dark-grey;
        }
        span {
            margin-top: 3px;
        }
    }

    .overviewButtonActive {
        svg {
            fill: $color-strauss-black;
        }
    }

    .doneButtonMobile {
        position: absolute;
        right: 20%;
        bottom: 17px;
        font-family: 'Roboto Medium';
        width: 50px;
        height: 50px;
        margin: 0;
        margin-right: -25px;
        background-color: transparent;
        border: none;
        font-size: 12px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: inherit;
        svg {
            width: 30px;
            height: 30px;
            fill: $color-strauss-dark-grey;
        }
        span {
            margin-top: 3px;
        }
    }
    
    .mobileMenuBottom {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: 80px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        background-color: $color-strauss-white;
        z-index: 1;
    }
    
    .mobileMenuBottomCircle {
        position: absolute;
        left: 50%;
        width: 100px;
        top: -50px;
        height: 100px;
        border-radius: 50%;
        margin-left: -50px;
        background-color: $color-strauss-white;
        z-index: 1;
    }

    .moduleAddButtonMobile {
        position: absolute;
        left: 10px;
        top: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        background-color: $color-strauss-red;
        border: none;
        margin: 0;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: inherit;
        border-radius: 50%;
        svg {
            width: 50%;
            height: 50%;
            fill: $color-strauss-white;
        }
    }

    .moduleAddButton {
        display: none;
    }

    .moduleAddButtonMobileActive {
        background-color: $color-strauss-black;
    }

    .moduleContainer {
        width: 100%;
        max-width: initial;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        position: absolute;
        left: 0;
    }

    .moduleContainerScroll {
        padding-bottom: 100px;
    }

    .slideIn {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }

    .linkfooter {
        display: none !important;
    }

        
    .editorWidgetIcon {
        width: 30px;
        height: 30px;
        svg {
            top: 7px;
            left: 7px;
            width: 16px;
            height: 16px;
        }
    }

    .editorWidgetIconMove {
        margin-left: -15px;
        margin-top: -15px;
    }

    .editorWidgetIconHeight {
        margin-left: -15px;
    }
    .editorWidgetIconExtend {
        margin-top: -15px;
    }
}