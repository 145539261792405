@import './theme.module.scss';

.leftColumn {
    padding: 0px 60px 0px 60px;
}

.leftColumnContainer {
    padding: 120px 30px 30px 30px;
}

.header {
    color: $color-strauss-black;
    font-size: 52px;
    margin-top: 100px;
    line-height: 73px;
}

.subHeader {
    font-family: 'Roboto Regular';
    font-size: 16px;
    line-height: 19px;
    text-transform: unset;
    margin-top: 18px;
}

.pocketButton {
    margin-top: 72px;
    width:100%;
    font-size: 22px;

    svg {
        height: 70%;
        fill: $color-strauss-white;
        color: $color-strauss-white;
        margin-right: 20px;
    }
}

.code {
    margin-top: 120px;
    position: relative;

    p {
        margin-top: 17px;
        font-size: 14px;
    }
}

.codeButton {
    height: 30px;
    width: 30px;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        height: 50%;
        fill: $color-strauss-white;
        color: $color-strauss-white;
    }
}

.error {
    color: $color-strauss-red;
    font-weight: bold;
}

.language {
    position: absolute;
    top: 10px;
    left: 60px;
    width: initial;
    z-index: 1;
}
.previewBackground {
    background: transparent linear-gradient(180deg, rgba(235,235,235,1) 0%, rgba(245,245,245,1) 67%, rgba(239,239,239,1) 67.5%, rgba(235,235,235,1) 100%);
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 100vh;
}

.previewBackgroundText {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    font-family: 'Augusta Regular';
    font-size: 260px;
    color: #000000;
    opacity: 0.03;
    overflow-y: hidden;
}

.mobilePreview {
    display: none;
}

@media ( max-width: 1850px ) { 
    .pocketButton {
        font-size: 16px;
        padding: 0 15px;
    }
}

@media ( max-width: 1440px ) { 
    .leftColumnContainer {
        padding: 50px 0 20px 0;
    }

    .header {
        margin-top: 40px;
        font-size: 48px;
    }

    .code {
        margin-top: 40px;
    }

    .language {
        left: 40px;
    }

    .pocketButton {
        font-size: 16px;
        padding: 0 5px;
    }
}

@media ( min-width: 992px) and (max-height: 1000px ) { 
    
    .previewBackgroundText {
        font-size: 200px;
    }

    .leftColumnContainer {
        padding: 50px 0 20px 0;
    }

    .header {
        margin-top: 40px;
        font-size: 32px;
        line-height: 36px;
    }
    .code {
        margin-top: 40px;
    }

    :global(.container-fluid) {
        overflow-y: scroll;
        margin-bottom: 10px;
    }

    .language {
        display: none;
    }
}

@media ( max-width: 991px ) { 
    
    .container {
        width: 100%;
        padding: 20px;
        position: relative;
        z-index: 1;
        height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
        height: -webkit-fill-available;
        min-height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
        min-height: -webkit-fill-available;
    }

    :global(.container-fluid) {
        overflow-y: scroll;
        margin-bottom: 10px;
    }

    .leftColumn {
        padding: 20px;
        height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
        height: -webkit-fill-available;
        min-height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
        min-height: -webkit-fill-available;
    }

    .leftColumnContainer {
        padding: 0;
    }
    
    .header {
        margin-top: 40px;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
    }
    
    .subHeader {
        margin-top: 12px;
        font-size: 14px;
        text-align: center;
    }


    .mobilePreview {
        display: block;
        display: block;
        height: 50vh;
        margin-top: -50px;
    }
    
    .rightColumnContainer {
        display: none;
    }

    .pocketButton {
        margin-top: -100px;
        position: relative;

        svg {
            height: 65%;
        }
    }

    .code {
        margin-top: 30px;

        input[type="text"] {
            font-size: 14px;
        }

        p {
            margin-top: 9px;
            font-size: 10px;
        }
    }

    
    .languageLink {
        display: none;
    }
}

