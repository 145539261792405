@import './theme.module.scss';

.editorCenter {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: calc(100% - (40px + min(50vw, 500px)));
    display: flex;
    justify-content: center;
    align-items: center;
}
.editorOuterContainer {
    position: relative;
    width: calc(min(80%, 80vh));
    padding-bottom: calc(min(80%, 80vh) * #{$const-strauss-sketch-height} / #{$const-strauss-sketch-width});
}
.editorContainer {
    background-color: $color-strauss-mid-grey;
    position: absolute;
    width: 100%;
    height: 100%;
}

.allowedRegion {
    position: absolute;
    background-color: $color-strauss-green;
}

.editorBorderBackground {
    position: absolute;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background-color: $color-strauss-white;
    overflow: hidden;
}
.editorBackground {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: transparent;

    background-image: url('../icons/grid.svg');
    background-size: calc(100% / #{$const-strauss-sketch-width});
    overflow: hidden;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.editorModule {
    position: absolute;
    background-size: 100% 100%;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
    cursor: pointer;

    img {
        width: 100%;
        height: 100%;
        pointer-events: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        transform: scaleX(-1);
    }
}

.editorModuleRight {
    img {
        transform: scaleX(1);
    }
}

.editorModuleLeft {
    img {
        transform: scaleX(-1);
    }
}

.editorPocketDirection-right {
    .editorModule {
        img {
            transform: scaleX(1);
        }
    }
}

.editorModuleMove {
    cursor: grabbing;
}

.editorModuleActive::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    content: ' ';
    border: 5px solid $color-strauss-blue;
}

.editorModuleNotAllowed::after {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    content: ' ';
    border: 5px solid $color-strauss-red;
}

.editorWidgetScale {
    position: absolute;
    width: 32px;
    height: 32px;
    border-radius: 55%;
    border: 5px solid $color-strauss-blue;
    background-color: $color-strauss-white;
    z-index: 1;
}

.editorWidgetScaleTopLeft {
    left: 0;
    top: 0;
    margin-left: -16px;
    margin-top: -16px;
}
.editorWidgetScaleTopRight {
    right: 0;
    top: 0;
    margin-right: -16px;
    margin-top: -16px;
}
.editorWidgetScaleBottomRight {
    right: 0;
    bottom: 0;
    margin-right: -16px;
    margin-bottom: -16px;
}
.editorWidgetScaleBottomLeft {
    left: 0;
    bottom: 0;
    margin-left: -16px;
    margin-bottom: -16px;
}
.editorWidgetScaleTop {
    left: 50%;
    top: 0;
    margin-left: -16px;
    margin-top: -16px;
}
.editorWidgetScaleLeft {
    left: 0;
    top: 50%;
    margin-left: -16px;
    margin-top: -16px;
}
.editorWidgetScaleRight {
    right: 0;
    top: 50%;
    margin-right: -16px;
    margin-top: -16px;
}
.editorWidgetVolumeRight {
    right: -5px;
    top: 50%;
    margin-right: -16px;
    margin-top: -16px;
}


.editorVolumeBase {
    position: absolute;
    height: calc(100% + 10px);
    left: calc(9 * 100% / #{$const-strauss-sketch-width} );
    top: -5px;
    width: calc(100% / #{$const-strauss-sketch-width});
    border: 5px solid $color-strauss-mid-grey;
    background-color: $color-strauss-white;
}

.editorModuleSide {
    position: absolute;
    left: calc(9 * 100% / #{$const-strauss-sketch-width} );
    background-size: 100% 100%;
    border: 5px solid $color-strauss-blue;
    box-sizing: border-box;

    img {
        width: 100%;
        height: 100%;
        pointer-events: none;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
}

.printViewBackground {
    background-color: rgba(#000000, 0.8);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.printView {
    position: relative;
    background: $color-strauss-white;
    padding: 40px;
    border-radius: 10px;
}
.printViewHeader {
    font-size: 28px;
    color: $color-strauss-black;
    height: 48px;
    line-height: 48px;
    vertical-align: middle;
}
.printViewCloseButton {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 48px;
    height: 48px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
        height: 40%;
    }
}
.printViewPageContainer {
    position: relative;
    border: 1px solid $color-strauss-dark-grey;
    height: 50vh;
    width: 35.353535vh;
    margin-top: 55px;
    margin-bottom: 55px;
    margin-left: auto;
    margin-right: auto;
}
.printViewModuleContainer {
    transform: rotate(90deg);
    position: relative;
    margin-top: 4vh;
    margin-right: calc(2.525252vh * 2);
    height: 35.353535vh;
}
.printPageHeader {
    color: $color-strauss-black;
    font-size: 1.4vh;
    margin-left: 1.5vh;
    margin-top: 2.5vh;
    margin-bottom: 0.2vh;
}
.printPageCode {
    margin-left: 1.5vh;
    font-size: 1vh;
    font-family: 'Roboto Regular';
}
.editorContainerPrint {
    background-color: $color-strauss-black;
}
.editorOuterContainerPrint {
    width: calc(35.353535vh);
    padding-bottom: calc(35.353535vh * #{$const-strauss-sketch-height} / #{$const-strauss-sketch-width});
}
.editorBackgroundPrint {
    background-image: none;
    background-color: $color-strauss-white;
}
.editorBorderBackgroundPrint {
    left: 3px;
    top: 3px;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
}
.editorPrintCutLine {
    position: absolute;
    top: 9vh;
    width: 90%;
    left: 5%;
    border-bottom: 2px dashed #f0f1f1;
}

@media print {
    .printViewModuleContainer {
        transform: rotate(90deg);
        position: absolute;
        top: 120mm;
        left: 31mm;
        width: 240mm;
        height: 215mm;
        margin: 0;
        padding: 0;
    }
    .editorOuterContainerPrint {
        width: 240mm;
        height: 215mm;
        margin: 0;
        padding: 0;
    }
}

.screenViewModuleContainer {
    position: relative;
    height: 100%;

    .editorOuterContainerPrint {
        padding-bottom: 95px;
        width: calc(95px * #{$const-strauss-sketch-width} / #{$const-strauss-sketch-height});
    }

    .editorBorderBackground {
        position: absolute;
        left: 3px;
        top: 3px;
        width: calc(100% - 6px);
        height: calc(100% - 6px);
        background-color: $color-strauss-white;
        overflow: hidden;
    }
    .editorContainerPrint {
        background-color: $color-strauss-dark-grey;
    }
}

.previewContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.moduleImage {
    width: 100%;
    height: 100%;
    pointer-events: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
}

.pocketOverlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../public/images/pocket_overlay.svg');
    background-position: top left;
    background-size: 100%;
    pointer-events: none;
}

.pocketOverlayActive {
    background-image: url('../../public/images/pocket_overlay_active.svg');
}

.editorOuterContainerPreview {
    .pocketOverlay {
        background-image: url('../../public/images/pocket_overlay_small.svg');
    }
}

.pocketOverlay.editorModuleRight {
    transform: scaleX(-1);
}

@media ( min-width: 992px) and (max-height: 1000px ) { 
    .screenViewModuleContainer {
        .editorOuterContainerPreview {
            padding-bottom: 60px;
            width: calc(60px * #{$const-strauss-sketch-width} / #{$const-strauss-sketch-height});
        }
    }
}

@media ( max-width: 991px ) { 
    .screenViewModuleContainer {
        justify-content: center;
        align-items: center;
        display: flex;
        width: 50px;

        .editorOuterContainerPrint {
            display: none;
            padding-bottom: 40px;
            width: calc(40px * #{$const-strauss-sketch-width} / #{$const-strauss-sketch-height});
        }
    }
}