@import './theme.module.scss';

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.8);
    z-index: 100;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonCircle {
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        height: 40%;
    }
}

.buttonClose {
    position: absolute;
    top: 56px;
    right: 56px;
}

.container {
    background-color: $color-strauss-white;
    border-radius: 5px;
    padding: 56px;
    width: 80vw;
    position: relative;
}

.scrollContainer {
    max-height: 90vh;
    overflow-y: overlay;
    overflow-x: hidden;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
}
.scrollContainer::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}


.title {
    font-family: 'Augusta Regular';
    text-transform: uppercase;
    font-size: 28px;
}

.subtitle {
    font-family: 'Roboto Regular';
    font-size: 19px;
    margin-top: 32px;
    margin-bottom: 24px;
}

.infoBackground {
    background-color: $color-strauss-light-grey;
    border-radius: 5px;
    min-height: 30vh;
    position: relative; 
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: 15px solid transparent;
}

.infoBackgroundFooter {
    margin-top: 24px;
    display: flex;
    align-items: center;
}

.infoNumber
{
    width: 54px;
    min-width: 54px;
    height: 54px;
    min-height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-strauss-dark-grey;
    color: $color-strauss-white;
    border-radius: 27px;
    font-family: 'Roboto Medium';
    font-size: 19px;
}

.infoText {
    margin: 0 0 0 24px;
    font-family: 'Roboto Regular';
}

.iconContainer {
    width: 54px;
    min-width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $color-strauss-light-grey;
    border-radius: 27px;
    svg {
        width: 24px;
        height: 24px;
    }
}

.subtitleFooter {
    margin-top:56px;
}

.footerContainer {
    display: flex;
    flex-direction: row;
    gap: 130px;
}

.footerEntry {
    display: flex;
}

.footerEntryTextContainer {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
    justify-content: center;
}

.footerText {
    font-family: 'Roboto Regular';
    font-size: 13px;
}

.footerBold {
    font-family: 'Roboto Bold';
    font-size: 13px;
    font-weight: bold;
    margin-top: 8px;

    a {
        text-decoration: none;
    }
}


@media ( max-width: 991px ) { 
    .background {
        height: 100%;
        z-index: 1000;
    }

    .infoBackground {
        margin-top: 20px;
    }
    
    .container {
        height: 100%;
        overflow-y: scroll;
        padding: 28px;
    }

    .title {
        padding-right: 40px;
    }
    
    .buttonClose {
        position: absolute;
        top: 28px;
        right: 28px;
    }

    .scrollContainer { 
        height: initial;
        overflow: hidden;
        max-height: initial;
    }

    .footerContainer {
        flex-direction: column;
        gap: 20px;
    }
}

@media ( max-width: 767px ) { 

    .container {
        width: 100vw;
    }
}