@import './theme.module.scss';

.container {
    padding: 0;
    display: flex;
    flex-direction: column;
}
.header {
    padding: 25px 40px 0px 40px;
}

.pocketContainer {
    height: 100%;
}

.pocketScrollContainerContainerContainer {
    max-height: 50vh;
    height: 50vh;
    position: relative;
}

.pocketScrollContainerContainer {
    position: relative;
    padding: 30px 0;
    height: 100%;
    overflow: hidden;
}

.pocketScrollContainer {
    overflow-y: overlay;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    height: 100%;
}
.pocketScrollContainer::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}


.language {
    display: flex;
    justify-content: center;
}

.module {
    margin-bottom: 17px;
}

.moduleTitle {
    font-family: 'Roboto Medium';
    font-size: 16px;
    font-weight: bold;
}

.moduleTextContainer {
    font-family: 'Roboto Regular';
}

.pocket {
    background-color: $color-strauss-white;
    border: 1px solid $color-strauss-mid-grey;
    border-radius: 10px;
    margin-bottom: 12px;
    padding: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    .modulePreview {
        width: 50px;
        text-align: center;
        margin-right: 20px;
    }

    img {
        height: 40px;
        pointer-events: none;
    }

    p {
        font-size: 12px;
        margin: 0;
    }

    hr {
        color: $color-strauss-mid-grey;
    }

    .pocketTextContainer {
        padding: 0 0px;
        flex-grow: 1;
        display: flex;
        flex-direction: row;

    }

    .pocketTitle {
        font-weight: bold;
        font-size: 18px;
    }

    .pocketLeft {
        margin-left: 100px;
    }

    .pocketCenter {
        flex-grow: 1;
        padding: 0;
    }
        
    .pocketRight {
        display: flex;
        flex-direction: column;
        gap: 35px;
    }

    .pocketRightRow {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .pocketButton {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .pocketCount {
        width: 30px;
        height: 30px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        vertical-align: middle;
        line-height: 30px;
    }

    .pocketButtonRound {
        border-radius: 50%;
    }

        
    .pocketTotalText {
        font-family: 'Roboto Medium';
        font-size: 16px;
        font-weight: bold;
    }
    .pocketTotalVat {
        font-family: 'Roboto Regular';
        font-size: 12px;
    }

    .pocketTotalPrice {
        font-family: 'Roboto Regular';
        font-size: 16px;
        font-weight: bold;
        text-align: right;
    }


    .pocketTable {
        display: flex;
        justify-content: space-between;

        svg {
            height: 40px;
            pointer-events: none;
        }
    }

    .arrowLeft {
        height: 13px;
        width: 13px;
        svg {
            height: 100%;
            width: 100%;
        }

    }
}

.pocketExpanded {

}
 
.content {
    padding: 80px 40px 0px 40px;
    border-top: 2px solid $color-strauss-editor-background;
    background-color: $color-strauss-white;
    position: relative;
    flex: 1;
}

.contentForm {
    flex-grow: 1;
}

.postScreenTitleContainerLeft {
    padding: 220px 100px 100px 100px;
    max-width: 800px;
}
.postScreenTitleContainerRight {
    padding: 100px 40px 100px 100px;
}

.postScreenTitle {
    font-family: 'Augusta Regular';
    font-size: 60px;
    text-transform: uppercase;
    margin-bottom: 55px;
}

.postScreenInfo {
    font-family: 'Roboto Medium';
    font-size: 18px;
}

.postScreenOverviewTitle {
    font-family: 'Augusta Regular';
    font-size: 38px;
    text-transform: uppercase;
}

.previewContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.header {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.buttonCircle {
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        height: 40%;
    }
}
.buttonInfo {
    margin-left: 70px;
}

.policyText {
    vertical-align: middle;
    font-size: 11px;
    font-family: 'Roboto Medium';
}

.policyCheckbox {
    margin-top: 12px;
    input {
        border-radius: 50% !important;
        background-color: $color-strauss-white !important;
        border-color: $color-strauss-black !important;
        width: 20px;
        height: 20px;
    }

    input:checked {
        background-color: $color-strauss-black !important;
        border-color: $color-strauss-black !important;
    }

    label {
        font-family: 'Roboto Medium';
        font-weight: bold;
        font-size: 13px;
        margin-left: 8px;
    }
}

.footer {
    padding: 25px 40px 0px 40px;
}

.pocketFooter {
    .pocketFooterLeft {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: row;
    }

    .pocketFooterRight {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: row;
    }
}

.buttonBack {
    svg {
        height:30%;
        margin-right: 10px;
    }
    margin-right: 20px;
}

.linkfooter {
    justify-content: flex-end !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

@media ( max-width: 1440px ) { 
    .content {
        padding: 20px;
    }
    .postScreenTitleContainerLeft {
        padding: 20px;
    }
    .postScreenTitleContainerRight {
        padding: 0;
        margin-top: 30px;
    }
}


@media ( max-width: 991px ) { 
    .language {
        display: none;
    }

    .header {
        padding: 25px 20px 20px 20px;
        background-color: $color-strauss-white; 
        z-index: 10;
        width: 100%;
        position: fixed;
    }

    .buttonCircle {
        margin-left: 10px;
        height: 28px;
        width: 28px;
    }

    .content {
        padding: 20px;
        padding-top: 100px;
    }

    .postScreenTitleContainerLeft {
        padding: 0;
    }
    .postScreenTitleContainerRight {
        padding: 0;
        margin-top: 30px;
    }

        
    .postScreenTitle {
        font-size: 28px;
        margin-bottom: 30px;
    }

    .postScreenOverviewTitle {
        font-size: 28px;
        margin-bottom: 0px;
    }

    .pocketScrollContainerContainerContainer {
        max-height: initial;
        height: auto;
        padding: 0 10px;
    }

    .pocketFooterRight {
        flex-wrap: wrap;
        justify-content: center !important;
    }

    .footer {
        padding: 0 20px;
    }
   
    .buttonBack {
        min-width: 70%;
        margin: 20px 0 0 0;
    }
    .buttonFinal {
        min-width: 70%;
        margin: 20px 0;
    }

    .linkfooter {
        justify-content: space-between !important;
    }
}

@media ( min-width: 992px) and (max-height: 1300px ) { 
    .content {
        padding: 40px;
        padding-top: 0;
    }

    .postScreenTitleContainerRight {
        padding: 40px;
        padding-bottom: 0;
    }

}