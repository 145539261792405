@import './theme.module.scss';

.container {
    padding: 25px 40px 0px 40px;
    display: flex;
    flex-direction: column;
}
.content {
    background-color: $color-strauss-editor-background;
    position: relative;
    flex: 1;
}

.previewContainer {
    background: transparent linear-gradient(180deg, rgba(235,235,235,1) 0%, rgba(245,245,245,1) 67%, rgba(239,239,239,1) 67.5%, rgba(235,235,235,1) 100%);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.header {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.language {
    display: flex;
    justify-content: center;
}

.buttonCircle {
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        height: 40%;
    }
}
.buttonInfo {
    margin-left: 70px;
}

.pocketInfoShipping {
    font-size: 13px;
    font-family: 'Roboto Medium';
}

.pocketContainer {
    margin: 40px 40px 40px 0;
    padding: 40px;
    position: absolute;
    right: 0;
    background-color: $color-strauss-white;
    box-shadow: 0px 2px 4px #0000000D;
    border-radius: 5px;
    height: calc(100% - 80px);
    width: 50vw;
    max-width: 880px;
}

.pocketScreenTitle {
    font-family: 'Augusta Regular';
    font-size: 38px;
    text-transform: uppercase;
    margin-bottom: 0;
}

.pocketScreenShareContainer {
    position: relative;
    overflow: visible;
}

.pocketScreenShare {
    font-family: 'Roboto Medium';
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

input[type="text"].pocketScreenShareInput {
    font-family: 'Roboto Regular';
    font-size: 12px;
    margin-bottom: 0;
    font-style:normal;
    border: 1px solid $color-strauss-mid-grey;
    padding: 10px 18px;
    border-radius: 5px;
    height: 35px;
}

.pocketScreenShareInputCopyButton {
    background-color: $color-strauss-red;
    color: #FFFFFF;
    border: none;
    border-radius: 0 5px 5px 0;
    position: absolute;
    right:0;
    bottom: 0;
    height: 35px;
    width: 35px;
    padding: 0px 8px;

    svg {
        margin-top: -3px;
    }
}

.pocketScreenShared {
    font-family: 'Roboto Medium';
    font-size: 12px;
    text-transform: none;
    margin-bottom: 0;
    margin-top: 5px;
    opacity: 0;
    transition: opacity 1s;
    position: absolute;
    top: 100%;
    right: 0;
    width: 300px;
    right: 0;
    z-index: 2;
    text-align: right;
}

.pocketScreenSharedVisible {
    opacity: 1.0;
}

.pocketScrollContainerContainer {
    position: relative;
    padding: 30px 0;
    margin-top: 30px;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: hidden;
}

.pocketScrollContainer {
    overflow-y: overlay;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    height: 100%;
}
.pocketScrollContainer::-webkit-scrollbar { /* WebKit */
    width: 0;
    height: 0;
}


.pocket {
    background-color: $color-strauss-white;
    border: 1px solid #F0F1F1;
    border-radius: 10px;
    margin-bottom: 12px;
    padding: 15px 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    font-family: 'Roboto Regular';

    /*img {
        height: 30px;
        pointer-events: none;
    }*/

    p {
        font-size: 12px;
        margin: 0;
    }

    .pocketTextContainer {
        padding: 0 10px 0 0;
        flex-grow: 1;
        display: flex;
        flex-direction: row;

    }

    .pocketTitle {
        font-weight: bold;
        font-size: 18px;
        font-family: 'Roboto Bold';
    
    }

    .pocketCheckContainer {
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        align-items: center;

        .pocketCheck {
            width: 40px;
            height: 40px;
            border-radius: 20px;
            border: solid 1px $color-strauss-light-grey;
        }

        .pocketAttached {
            border: solid 1px $color-strauss-black;
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
                width: 13px;
                height: 13px;
                fill: $color-strauss-black;
            }
        }
    }

    .pocketLeft {
        
    }

    .pocketCenter {
        flex-grow: 1;
        padding-left: 50px;
    }
        
    .pocketRight {
        display: flex;
        flex-direction: column;
        gap: 35px;
    }

    .pocketRightRow {
        display: flex;
        flex-direction: row;
        gap: 20px;
    }

    .pocketButton {
        width: 30px;
        height: 30px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .pocketCount {
        width: 30px;
        height: 30px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
        vertical-align: middle;
        line-height: 30px;
    }

    .pocketButtonRound {
        border-radius: 50%;
    }
}

.pocketFooter {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    flex-shrink: 0;
}

.pocketActive {
    border: solid 1px $color-strauss-black;
}

.pocketAddButton {
    border-radius: 10px;
    text-transform: uppercase;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100px;
    font-size: 16px;

    svg {
        height: 60%;
        margin-right: 20px;
    }
}

.buttonFinal {
    height: 55px;
}

.slideIn {
    background: transparent linear-gradient(180deg, #FFFFFF 0%, #FFFFFF 49%, #FFFFFFCC 68%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    border-radius: 5px 5px 0px 0px;
    opacity: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 1;
}
.slideOut {
    background: transparent linear-gradient(0deg, #FFFFFF 0%, #FFFFFF 49%, #FFFFFFCC 68%, #FFFFFF00 100%) 0% 0% no-repeat padding-box;
    border-radius: 0px 0px 5px 5px;
    opacity: 1;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 1;
}


.pocketFooterTotalContainerMobile {
    display: none;
}

.linkfooter {
    justify-content: flex-end !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}



@media ( max-width: 1440px ) { 
    .pocketScreenTitle {
        font-size: 30px;
    }
}

@media ( min-width: 992px) and (max-height: 1000px ) { 
    
    .pocketScreenTitle {
        font-size: 30px;
    }

    .pocketContainer {
        padding: 20px;
    }

    .pocketScrollContainerContainer {
        padding: 0;
    }

    .slideIn,.slideOut {
        height: 0;
    }

    .pocketInfo {
        margin-bottom: 5px;
        margin-top: 5px;
    }
    .pocket {
        .pocketRight {
            gap: 5px;
        }
        .pocketRightRow {
            gap: 5px;
        }
    }
}

@media ( max-width: 991px ) { 
    .container {
        padding: 0;
    }
    .content {
        background-color: $color-strauss-white;
        position: absolute;
        left: 0;
        top: 0;
        height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
        height: -webkit-fill-available;
        width: 100vw;
    }
    .header {
        display: flex;
        flex-direction: row;
        padding: 20px;
        z-index: 1;
        background-color: $color-strauss-white;
    }
    
    .language {
        display: none;
    }

    .buttonCircle {
        margin-left: 10px;
        height: 28px;
        width: 28px;
    }

    .linkfooter {
        display: none !important;
    }
        
    .previewContainer {
        background-image: none;
        background-color: $color-strauss-white;
        position: absolute;
        width: 100%;
        height: calc(100vh - 68px - 80px);
        top: 68px;
        left: 0;
    }

    .pocketContainer {
        display: none;
    }

    .pocketContainerActive {
        display: initial;
        position: absolute;
        left: 0;
        bottom: 25px;
        margin: 0;
        padding: 0 30px;
        height: calc(70vh - 50px);
        width: 100%;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        z-index: 100;
        box-shadow: 0px 0px 20px #0000000d;
    }
    
    .pocketScrollContainerContainer {
        margin-top: 0;
    }

    .pocketScrollContainer {
        height: calc(70vh - 150px);
    }
    
    .pocket {
        padding: 5px;
        position: relative;

            
        .pocketCheckContainer {
            margin: 0 10px !important;
            position: absolute;
            left: 0;
            top: 18px;
            z-index: 1;

            .pocketAttached {
                border: solid 1px $color-strauss-black;
                background-color: $color-strauss-white;
                svg {
                    width: 13px;
                    height: 13px;
                    fill: $color-strauss-black;
                }
            }
        }
    }

    .pocketCenter {
        padding-left: 10px !important;
    }

    .pocketRight {
        gap: 10px !important;
    }

    .pocketRightRow {
        gap: 10px !important;
    }

    .pocketInfoText {
        font-size: 10px !important;
    }

    .pocketTitle {
        font-size: 14px !important;
    }

    .overviewButton {
        position: absolute;
        left: 20%;
        bottom: 17px;
        font-family: 'Roboto Medium';
        width: 50px;
        height: 50px;
        margin: 0;
        margin-left: -25px;
        background-color: transparent;
        border: none;
        font-size: 12px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: inherit;
        svg {
            width: 30px;
            height: 30px;
            fill: $color-strauss-dark-grey;
        }
        span {
            margin-top: 3px;
        }
    }

    .overviewButtonActive {
        svg {
            fill: $color-strauss-black;
        }
    }

    .doneButtonMobile {
        position: absolute;
        right: 20%;
        bottom: 17px;
        font-family: 'Roboto Medium';
        width: 150px;
        height: 50px;
        margin: 0;
        margin-right: -75px;
        background-color: transparent;
        border: none;
        font-size: 12px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: inherit;
        svg {
            width: 30px;
            height: 30px;
            fill: $color-strauss-dark-grey;
        }
        span {
            margin-top: 3px;
        }
    }
    
    .mobileMenuBottom {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: 80px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        background-color: $color-strauss-white;
        z-index: 101;
    }
    
    .mobileMenuBottomCircle {
        position: absolute;
        left: 50%;
        width: 100px;
        top: -50px;
        height: 100px;
        border-radius: 50%;
        margin-left: -50px;
        background-color: $color-strauss-white;
        z-index: 1;
    }

    .moduleAddButtonMobile {
        position: absolute;
        left: 10px;
        top: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        background-color: $color-strauss-red;
        border: none;
        margin: 0;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: inherit;
        border-radius: 50%;
        svg {
            width: 50%;
            height: 50%;
            fill: $color-strauss-white;
        }
    }

    .mobileCode {
        width: 100%;
        padding: 0;
        margin-top: 30px;
    }

    .buttonFinal {
        display: none;
    }

    .pocketInfo {
        margin-bottom: 10px;
    }

    .pocketFooterTotalContainer {
        justify-content: center;
        align-items: center;
        flex-direction: row;
        flex-grow: 0;
    }

    .pocketFooterTotalVatForm {
        justify-content: center;
        margin-top: 5px;
        margin-bottom: 0 !important;

        .form-check-input {
            margin-right: 0 !important;
        }

        .form-switch {
            padding-left: 3em !important;
        }
    }
    
    .pocketFooterTotalContainerMobile {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: row;
        margin-top: 20px;
        margin-bottom: 10px;

        .pocketFooterTotalContainerLeftMobile {
            display: flex;
            justify-content: center;
            flex-direction: column;
            flex-grow: 1;
        }

        .pocketFooterTotalContainerRightMobile {
            display: flex;
            justify-content: center;
            flex-direction: column;
            flex-grow: 1;
            align-items: flex-end;
        }

        .pocketFooterTotalVatForm {
            flex-direction: row;
            display: flex;
            justify-content: flex-start;
            gap: 5px;
            margin-top: 0;
            font-size: 10px;
        }

        .pocketInfoShipping {
            font-size: 10px;
            margin: 0;
        }

        .pocketFooterTotalText {
            margin-bottom: 0;
            font-family: 'Roboto Bold';
        }

        .pocketFooterTotalPrice {
            margin-bottom: 0;
            font-size: 30px;
            font-family: 'Roboto Bold';
        }
    }

    .pocketAddButton {
        height: 82px;
        line-height: initial;
        margin-bottom: 20px;
    }
}