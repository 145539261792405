@import './theme.module.scss';

.languageLink {
    font-family: 'Roboto Regular';
    margin: 0 10px;
    text-decoration: none;
    color: $color-strauss-black;
    font-size: 16px;
}

.languageLink:hover {
    color: $color-strauss-dark-grey;
}

.languageLink:active {
    color: $color-strauss-light-grey;
}

.languageLink.selected {
    font-weight: bold;
}
