@import './theme.module.scss';

.container {
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.pocketContainer {
    height: 100%;
}

.pocketScreenTitle {
    font-family: 'Augusta Regular';
    font-size: 38px;
    text-transform: uppercase;
}

.content {
    padding: 80px 40px 0px 40px;
    border-top: 2px solid $color-strauss-editor-background;
    background-color: $color-strauss-white;
    position: relative;
    flex: 1;
}


.language {
    display: flex;
    justify-content: center;
}

.contentForm {
    flex-grow: 1;

    label {
        font-family: 'Roboto Regular';
        font-size: 14px;
        width: 100%;
        margin-bottom: 40px;
    }

    .error {
        label {
            color: $color-strauss-red;
            font-weight: bold;
        }
    }

    select {
        width: 100%;
        border: 1px solid $color-strauss-mid-grey;
        background-color: $color-strauss-light-grey;
        border-radius: 10px;
        font-family: 'Roboto Medium';
        font-size: 18px;
        font-style: normal;
        padding: 0 20px;
        height: 50px;
        line-height: 50px;
        margin-top: 10px;
    }
    
    select:disabled {
        background-color: $color-strauss-white;
        color:  $color-strauss-dark-grey;
    }

    input[type="text"] {
        width: 100%;
        border: 1px solid $color-strauss-mid-grey;
        background-color: $color-strauss-light-grey;
        border-radius: 10px;
        font-family: 'Roboto Medium';
        font-size: 18px;
        font-style: normal;
        padding: 10px 20px;
        height: 50px;
        line-height: 50px;
        margin-top: 10px;
    }
    textarea {
        width: 100%;
        border: 1px solid $color-strauss-mid-grey;
        background-color: $color-strauss-light-grey;
        border-radius: 10px;
        font-family: 'Roboto Medium';
        font-size: 18px;
        font-style: normal;
        padding: 10px 20px;
        height: 172px;
        margin-top: 10px;
    }

    .radiobuttonContainer {
        width: 100%;
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        height: 50px;
        align-items: center;
            
        /* Customize the label (the container) */
        .radiobutton {
            display: block;
            position: relative;
            padding-left: 40px;
            margin-bottom: 0;
            cursor: pointer;
            font-size: 14px;
            font-family: 'Roboto Regular';
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            height: 30px;
            line-height: 30px;
        }
        
        /* Hide the browser's default radio button */
        .radiobutton input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }
        
        /* Create a custom radio button */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 30px;
            width: 30px;
            background-color: $color-strauss-white;
            border-radius: 50%;
            border: 1px solid $color-strauss-mid-grey;
        }
        
        /* On mouse-over, add a grey background color */
        .radiobutton:hover input ~ .checkmark {
            background-color: #ccc;
        }
        
        /* When the radio button is checked */
        .radiobutton input:checked ~ .checkmark {
            background-color: $color-strauss-white;
            border: 1px solid $color-strauss-red;
        }
        
        /* Create the indicator (the dot/circle - hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }
        
        /* Show the indicator (dot/circle) when checked */
        .radiobutton input:checked ~ .checkmark:after {
            display: block;
        }
        
        /* Style the indicator (dot/circle) */
        .radiobutton .checkmark:after {
            top: 4px;
            left: 4px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: $color-strauss-red;
        }
    }

    .phoneCode {
        position: absolute;
        top: 21px;
        width: 89px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .phoneLabel {
        position: relative;
        input[type="text"] {
            padding-left: 110px;
        }
    }
}

.previewContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.header {
    padding: 25px 40px 0px 40px;
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
}

.buttonCircle {
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        height: 40%;
    }
}
.buttonInfo {
    margin-left: 70px;
}

.policyText {
    vertical-align: middle;
    font-size: 11px;
    font-family: 'Roboto Medium';
}

.policyCheckbox {
    margin-top: 12px;
    input {
        border-radius: 50% !important;
        background-color: $color-strauss-white !important;
        border-color: $color-strauss-black !important;
        width: 20px;
        height: 20px;
    }

    input:checked {
        background-color: $color-strauss-black !important;
        border-color: $color-strauss-black !important;
    }

    label {
        font-family: 'Roboto Medium';
        font-weight: bold;
        font-size: 13px;
        margin-left: 8px;
    }
}

.footer {
    padding: 25px 40px 25px 0px;
}

.pocketFooter {
    .pocketFooterLeft {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        flex-direction: row;
    }

    .pocketFooterRight {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: row;
    }
}

.buttonBack {
    svg {
        height:30%;
        margin-right: 10px;
    }
}

.buttonFinalContainer {
    justify-content: flex-end;
    align-items: flex-end;
    display: flex;
}

.linkfooter {
    justify-content: flex-end !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}


@media ( max-width: 767px ) { 
    .header {
        padding: 25px 40px 20px 40px;
        background-color: $color-strauss-white; 
        z-index: 10;
        width: 100%;
        position: fixed;
    }

    .pocketScreenTitle {
        font-size: 20px;
    }
    
    .buttonCircle {
        margin-left: 16px;
        width: 28px;
        height: 28px;
    }

    .buttonFinalContainer {
        justify-content: center;
    }

    .buttonFinal {
        margin-top: 20px;
        min-width: 70%;
    }

    .buttonBack {
        margin-top: 30px;
        min-width: 70%;
    }

    .pocketFooterTotalVatForm {
        justify-content: center;
    }
    
    .pocketFooterLeft {
        justify-content: center !important;
    }
    
    .language {
        display: none;
    }

    .footer {
        padding: 20px;
    }
}