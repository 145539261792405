@import './theme.module.scss';

.pocketScreenShareContainer {
    position: relative;
    overflow: visible;
}

.pocketScreenShare {
    font-family: 'Roboto Medium';
    font-size: 12px;
    text-transform: uppercase;
    margin-bottom: 5px;
}

input[type="text"].pocketScreenShareInput {
    font-family: 'Roboto Regular';
    font-size: 12px;
    margin-bottom: 0;
    font-style:normal;
    border: 1px solid $color-strauss-mid-grey;
    padding: 10px 18px;
    border-radius: 5px;
    height: 35px;
}

.pocketScreenShareInputCopyButton {
    background-color: $color-strauss-red;
    color: #FFFFFF;
    border: none;
    border-radius: 0 5px 5px 0;
    position: absolute;
    right:0;
    bottom: 0;
    height: 35px;
    width: 35px;
    padding: 0px 8px;

    svg {
        margin-top: -3px;
    }
}

.pocketScreenShared {
    font-family: 'Roboto Medium';
    font-size: 12px;
    text-transform: none;
    margin-bottom: 0;
    margin-top: 5px;
    opacity: 0;
    transition: opacity 1s;
    position: absolute;
    top: 100%;
    right: 0;
    width: 300px;
    right: 0;
    z-index: 2;
    text-align: right;
}

.pocketScreenSharedVisible {
    opacity: 1.0;
}
