@import './theme.module.scss';

.container {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: $color-strauss-white;
    left: 0;
    top: 0;

    display: flex;
    justify-content: center;
    align-items: center;

        
    .center {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .info {
        font-size: 24px;
        font-family: 'Roboto Bold';
        margin-top: 40px;
        margin-bottom: 40px;
    }
}

.cookieBar {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 80px;
    border-top: 1px solid $color-strauss-dark-grey;
    background-color: $color-strauss-light-grey;
    z-index: 100;

    font-family: 'Roboto Medium';
    font-size: 14px;

    display: flex;
    align-items: center;
    justify-content: center;

    gap: 10px;

    p {
        margin: 0;
        line-height: 30px;
    }
}



@media ( max-width: 991px ) { 
    .cookieBar {
        padding: 30px;
        height: 150px;
    }
}