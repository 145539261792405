@import './theme.module.scss';

.container {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    padding-bottom: 15px;
    flex-wrap: wrap;
    gap: 20px;
    position: relative;
    z-index: -1;
}

.link {
    text-decoration: none;
    color: $color-strauss-black;
    font-size: 10px;
}

.link:hover {
    color: $color-strauss-dark-grey;
}

@media ( max-width: 1440px ) { 
    .container {
        justify-content: center;
        gap: 20px;
        padding-top: 0;
        padding-bottom: 20px;
    }

    .link {
        font-size: 8px;
    }
}

@media ( max-width: 991px ) { 

    .container {
        justify-content: center;
        gap: 20px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .link {
        font-size: 8px;
    }
}