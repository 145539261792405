@import './theme.module.scss';

.background {
    background: transparent linear-gradient(180deg, rgba(235,235,235,1) 0%, rgba(245,245,245,1) 67%, rgba(239,239,239,1) 67.5%, rgba(235,235,235,1) 100%);
    height: 100%;
    min-height: 100vh;
}

.container {
    width: 60%;
    padding: 370px 0px 40px 223px;
    position: relative;
    z-index: 1;
}

.footer {
    position: absolute;
    bottom: 0;
    right: 60px;
}

.splashCargo {
    position: absolute;
    max-height: 900px;
    top: 20%;
    left: 55%;
    height: 72%;
    width: 50%;
    background-image: url('../../public/images/splash_cargo.png');
    background-size: contain;
    background-repeat: no-repeat;
}

.language {
    position: absolute;
    top: 10px;
    left: 60px;
    width: initial;
    z-index: 1;
}

.header {
    margin-top: 110px;
    font-size: 96px;
}

.subHeader {
    margin-top: 80px;
    font-size: 42px;
    font-family: 'Augusta Regular';
}

.buttonStart {
    margin-top: 80px;
    height: 110px !important;
    border-radius: 55px !important;

    font-size: 30px;
    text-transform: uppercase;
    padding-left: 90px;
    padding-right: 90px;
}

.buttonStore {
    border-radius: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    font-size: 16px;
    text-transform: uppercase;
    padding-right: 30px;

    svg {
        height: 50%;
        margin-right: 30px;
    }
}

.arrow {
    position: relative;
    height: 40%;
    margin-left: 10px;
    svg {
        position: absolute;
        left: 0;
        top: 20%;
        fill: #fff;
        margin: 0;
    }
}

@media ( max-width: 1440px ) { 
    .container {
        padding: 85px 0px 40px 55px;
        width: 70%;
    }
    
    .splashCargo {
        right: 0;
        max-height: 50vw;
    }
}


@media ( min-width: 992px) and (max-height: 1000px ) { 
    
    .header {
        margin-top: 40px;
        font-size: 60px;
    }

    .subHeader {
        font-size: 30px;
    }

    .buttonStart {
        margin-top: 40px;
        height: 66px !important;
    }
}


@media ( max-width: 991px ) { 
        
    .container {
        width: 100%;
        padding: 10px;
        position: relative;
        z-index: 1;
        height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
        height: -webkit-fill-available;
        min-height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
        min-height: -webkit-fill-available;
    }
    .background {
        height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
        height: -webkit-fill-available;
        min-height: calc(100vh - env(safe-area-inset-bottom) - env(safe-area-inset-top));
        min-height: -webkit-fill-available;
    }
    

    .header {
        margin-top: 40px;
        font-size: 32px;
        text-align: center;
    }
    
    .subHeader {
        margin-top: 30px;
        font-size: 14px;
        text-align: center;
    }

    .splashCargo {
        width: 100%;
        max-width: 80%;
        max-height: 100%;
        height: 100%;
        background-position: center;
        left: 10%;
        top: 50px;
    }
    
    .buttonStore {
        border-radius: 18px;
        position: absolute;
        top: 10px;
        right: 10px;
        padding: 0 20px;

        height: 28px;
        width: auto;

        font-size: 12px;
        z-index: 1;

        svg {
            display: none;
            height: 50%;
            margin-right: 10px;
        }
    }

    .arrow {
        display: none;
    }

    .background {
        background-color: #fff;
        background-image: none;
    }

    .buttonStart {
        position: absolute;
        bottom: 110px;
        left: 30px;
        width: calc(100% - 60px);
        font-size: 14px;
        height: 66px !important;
        padding: 0;
    }

    .footer {
        width: 100%;
        left: 0;
        padding: 0 20px;
        bottom: 20px;
    }

    .language {
        position: absolute;
        bottom: 60px;
        left: 0;
        width: 100%;
        z-index: 1;
        top: initial;

        > div {
            justify-content: center;
        }
    }
}

