@import './theme.module.scss';

.container {
    display: flex;
    flex-direction: row;
    margin-right: auto;
}
.logo {
    height: 46px;
}
.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 46px;
}

.text {
    vertical-align: middle;
    font-family: 'Augusta Regular';
    font-size: 20px;
    padding-left: 12px;
    margin:0;
}

.textSmall {
    font-size: 10px;
}

.medium {
    .logo {
        height: 60px;
    }
    .text {
        font-size: 26px;
        padding-left: 20px;
    }
    .textSmall {
        font-size: 13px;
    }
}
.big {
    .logo {
        height: 88px;
    }
    .textContainer {
        height: 88px;
    }
    .text {
        font-size: 34px;
        padding-left: 32px;
    }
    .textSmall {
        font-size: 44px;
    }
}

.center {
    margin-right: initial;
}


@media ( max-width: 991px ) { 
    .logo {
        height: 28px;
    }
    .textContainer {
        height: 28px;
    }
    .text {
        font-size: 12px;
        padding-left: 10px;
    }
            
    .big {
        .logo {
            height: 28px;
        }
        .textContainer {
            height: 28px;
        }
        .text {
            font-size: 12px;
            padding-left: 10px;
        }
    }
    .medium {
        .logo {
            height: 28px;
        }
        .textContainer {
            height: 28px;
        }
        .text {
            font-size: 12px;
            padding-left: 10px;
        }
    }
}
