@import './theme.module.scss';

.container {
    padding: 25px 0px 0px 0px;
    display: flex;
    flex-direction: column;
}
.content {
    background: #fff;
    position: relative;
    flex: 1;
    border-top: 2px solid $color-strauss-mid-grey;
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centerContent {
    padding: 0;
}

.header {
    display: flex;
    flex-direction: row;
    margin-bottom: 25px;
    padding: 0 40px;
}

.language {
    display: flex;
    justify-content: center;
}

.buttonCircle {
    margin-left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        height: 40%;
    }
}
.buttonInfo {
    margin-left: 70px;
}

.centerHeader {
    padding: 0;
    color: $color-strauss-black;
    font-size: 60px;
}

.centerSubHeader {
    padding: 0;
    margin-top: 60px;
    margin-bottom: 100px;
    font-size: 18px;
    font-family: 'Roboto Medium';
    text-transform: none;
}

.linkfooter {
    justify-content: flex-end !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}

@media ( max-width: 991px ) { 
    .container {
        padding: 0;
    }

    .header {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;
        padding: 20px 20px 0 20px;
    }
    
    .language {
        display: none;
    }

    .buttonCircle {
        margin-left: 10px;
        height: 28px;
        width: 28px;
    }

    .pocketContainer {
        display: none;
        max-width: initial;
    }

    .pocketContainerActive {
        display: initial;
        position: absolute;
        left: 0;
        bottom: 50px;
        margin: 0;
        padding: 30px;
        height: calc(70vh - 50px);
        width: 100%;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        z-index: 1;
    }

    .pocketTitle {
        display: none;
    }

    .pocketNameContainer {
        input[type="text"] {
            width: calc(100% - 60px);
            margin-bottom: 16px;
            font-size: 18px;
        }
    }
    .pocketName {
        font-family: 'Augusta Regular';
        font-size: 18px;
        margin-bottom: 28px;
    }
    .modulesInfo {
        display: none;
    }

    .pocketScrollContainer {
        height: calc(70vh - 220px);
    }

    .pocketFooter {
        button {
            display: none;
        }
        display: flex;
        align-items: center;
        gap: 20px;
        position: absolute;
        width: calc(100% - 60px);
        left: 30px;
        bottom: 40px;
    }

    .pocketFooterTotalContainer {
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .pocketFooterTotalText {
        font-family: 'Roboto Regular' !important;
        font-size: 16px !important;
    }

    .pocketFooterTotalPrice {
        font-size: 22px !important;
    }

    .pocketButtonsDesktop {
        display: none;
    }

    .editorCenter {
        width: 100%;
    }

    .rulerHorizontalCenterContainer {
        display: none;
    }

    .rulerVerticalCenterContainer {
        display: none;
    }

    .editorCenter {
        align-items: flex-start;
        padding-top: 40px;
    }

    .editorOuterContainerContainer {
        width: 90vw;
        padding-bottom: calc(90vw * 21.5 / 24);
    }

    .content {
        background-color: $color-strauss-light-grey;
        background-image: none;
    }

    .printButton {
        display: none;
    }

    .editButton {
        width: 50px;
        height: 50px;
        border-radius: 5px;
        font-size: 8px;
        margin-bottom: 10px;
        padding: 0;
        svg {
            width: 17px;
            height: 17px;
            margin-bottom: 3px;
        }
        span {
            margin-top: 1px;
            text-transform: uppercase;
        }
    }
    
    .previewButton {
        position: absolute;
        right: 20px;
        bottom: 130px;
        margin-bottom: 0;
    }

    .pocketButton.mobileOnly {
        position: absolute;
        bottom: 130px;

        border: none;
        width: 60px;
        height: 50px;
        padding: 0;
        background: none;

        svg {
            width: 100%;
            height: 100%;
        }
    }

    .pocketButtonLeft {
        left: 20px;
    }

    .pocketButtonRight {
        left: 90px;
    }


    .pocketButtonLeft.buttonActive {
        background: none;
        background-color: transparent !important;
    }

    .pocketButtonRight.buttonActive {
        background: none;
        background-color: transparent !important;
    }

    .overviewButton {
        position: absolute;
        left: 20%;
        bottom: 17px;
        font-family: 'Roboto Medium';
        width: 50px;
        height: 50px;
        margin: 0;
        margin-left: -25px;
        background-color: transparent;
        border: none;
        font-size: 12px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: inherit;
        svg {
            width: 30px;
            height: 30px;
            fill: $color-strauss-dark-grey;
        }
        span {
            margin-top: 3px;
        }
    }

    .overviewButtonActive {
        svg {
            fill: $color-strauss-black;
        }
    }

    .doneButtonMobile {
        position: absolute;
        right: 20%;
        bottom: 17px;
        font-family: 'Roboto Medium';
        width: 50px;
        height: 50px;
        margin: 0;
        margin-right: -25px;
        background-color: transparent;
        border: none;
        font-size: 12px;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: inherit;
        svg {
            width: 30px;
            height: 30px;
            fill: $color-strauss-dark-grey;
        }
        span {
            margin-top: 3px;
        }
    }
    
    .mobileMenuBottom {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
        height: 80px;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        background-color: $color-strauss-white;
        z-index: 1;
    }
    
    .mobileMenuBottomCircle {
        position: absolute;
        left: 50%;
        width: 100px;
        top: -50px;
        height: 100px;
        border-radius: 50%;
        margin-left: -50px;
        background-color: $color-strauss-white;
        z-index: 1;
    }

    .moduleAddButtonMobile {
        position: absolute;
        left: 10px;
        top: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        background-color: $color-strauss-red;
        border: none;
        margin: 0;
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        line-height: inherit;
        border-radius: 50%;
        svg {
            width: 50%;
            height: 50%;
            fill: $color-strauss-white;
        }
    }

    .moduleAddButton {
        display: none;
    }

    .moduleAddButtonMobileActive {
        background-color: $color-strauss-black;
    }

    .moduleContainer {
        width: 100%;
        max-width: initial;
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
        position: absolute;
        left: 0;
    }

    .moduleContainerScroll {
        padding-bottom: 100px;
    }

    .slideIn {
        border-top-left-radius: 30px;
        border-top-right-radius: 30px;
    }

    .linkfooter {
        display: none !important;
    }

        
    .editorWidgetIcon {
        width: 30px;
        height: 30px;
        svg {
            top: 7px;
            left: 7px;
            width: 16px;
            height: 16px;
        }
    }

    .editorWidgetIconMove {
        margin-left: -15px;
        margin-top: -15px;
    }

    .editorWidgetIconHeight {
        margin-left: -15px;
    }
    .editorWidgetIconExtend {
        margin-top: -15px;
    }
}